import React, { Suspense, lazy, useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { io } from "socket.io-client";
import mixpanel from "mixpanel-browser";

import { patchRequest } from "./api";
import { getUserDetails } from "./utils/functions";
import {
  conversationsPermissions,
  broadcastPermissions,
  customersPermissions,
  insightsPermissions,
} from "./utils/permissions";
import {
  createConversationsUnreadCount,
  createConversationsUnreadMsgs,
  createNewMessageEvent,
} from "./redux/actions/Conversations";

import { PublicRoute, PrivateRoute } from "./components/Hoc";

import { fetchToken, onMessageListener } from "./firebase";

import "./styles/style.sass";
import { APP_NOTIFY_TOKEN, APP_ONEROUTE_USER } from "./utils/constants";
import { Store } from "./utils/store";

const Auth = lazy(() => import("./containers/Auth"));
const Home = lazy(() => import("./containers/Home"));
const GetStarted = lazy(() => import("./containers/GetStarted"));
const Conversations = lazy(() => import("./containers/Conversations"));
const Mentions = lazy(() => import("./containers/Mentions"));
const Csat = lazy(() => import("./containers/Csat"));
const Broadcasts = lazy(() => import("./containers/Broadcasts"));
const BroadcastInfo = lazy(() =>
  import("./containers/Broadcasts/BroadcastInfo")
);
const Customers = lazy(() => import("./containers/Customers"));
const CustomerDetails = lazy(() =>
  import("./containers/Customers/CustomerDetails")
);
const Insights = lazy(() => import("./containers/Insights"));
const Settings = lazy(() => import("./containers/Settings"));
const AgentInsightsDetails = lazy(() =>
  import("./containers/Insights/AgentInsightsDetails")
);

const userAuthToken =
  JSON.parse(window.localStorage.getItem(APP_ONEROUTE_USER))?.token || null;

const defaltNotifyToken =
  JSON.parse(window.localStorage.getItem(APP_NOTIFY_TOKEN)) || null;

const SOCKET_URL = process.env.REACT_APP_SOCKET_URL;

const store = new Store();

const App = () => {
  const dispatch = useDispatch();
  const { conversationsUnreadCount, conversationsUnreadMsgs } = useSelector(
    (state) => state.conversations
  );

  const [fetchedToken, setFetchedToken] = useState(null);
  const [notificationToken, setNotificationToken] = useState(defaltNotifyToken);

  const intervalRef = useRef(null);
  var msgUnreadCount = useRef();
  var unreadMessages = useRef();

  onMessageListener()
    .then(() => {})
    .catch((err) => console.log("failed: ", err));

  useEffect(() => {
    fetchToken(setFetchedToken);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const user = getUserDetails();

    if (fetchedToken) {
      // Token exists but hasn't changed
      if (notificationToken === fetchedToken) {
        console.log("Notification permission enabled 👍🏻");
        return;
      }

      // Token exists and has changed - update it
      if (user.id) {
        saveNotificationToken(user.id);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchedToken]);

  const saveNotificationToken = async (userId) => {
    await patchRequest({
      url: `users/${userId}/user-configs`,
      token: true,
      data: {
        webPushTokens: fetchedToken,
      },
    })
      .then((res) => {
        if (res?.data?.success) {
          setNotificationToken(fetchedToken);
          localStorage.setItem(APP_NOTIFY_TOKEN, JSON.stringify(fetchedToken));

          console.log("Notification permission enabled 👍🏻");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // MixPanel Starts Here
  useEffect(() => {
    mixpanel.init("3aae5910a49fe4c3e612812fbf2ad861");
  }, []);
  // MixPanel Ends Here

  useEffect(() => {
    msgUnreadCount.current = conversationsUnreadCount;
  }, [conversationsUnreadCount]);

  useEffect(() => {
    unreadMessages.current = conversationsUnreadMsgs;
  }, [conversationsUnreadMsgs]);

  // WebSocket.io Script Starts Here
  useEffect(() => {
    if (!userAuthToken) return; // Avoid connecting without auth

    const socket = io(SOCKET_URL, {
      auth: { token: userAuthToken },
      reconnectionDelay: 1000,
      reconnection: true,
      transports: ["websocket"],
      upgrade: false,
      forceNew: true,
    });

    const { id, firm_id } = getUserDetails();
    msgUnreadCount.current = conversationsUnreadCount;
    unreadMessages.current = conversationsUnreadMsgs;

    // Handle new messages
    socket.on("newMessage", (data) => {
      if (
        data?.conversation?.firm_id === firm_id &&
        (data?.conversation?.agent_id === id ||
          !data?.conversation?.agent_id) &&
        data?.message?.sender?.authUser === false
      ) {
        dispatch(createNewMessageEvent(data));

        const currentConvoId =
          window.location.pathname?.replace("/conversations/", "") || null;
        const incomingConvoId = `${data?.conversation?.customer_id}/${data?.conversation?.channel_id}`;
        const hasData = store.has(data?.message?.externalId)
        const newCount = hasData ? 0 : 1;
        const unreadMsgs = hasData ? {} : {
          id: data?.conversation?.id,
          status: data?.conversation?.status,
        }

        store.set(data?.message?.externalId, {setTime: new Date()});

        const incomingConvoAgentId = data?.conversation?.agent_id;

        if (currentConvoId !== incomingConvoId && incomingConvoAgentId === id) {
          const status = data?.conversation?.status;

          msgUnreadCount.current = {
            ...msgUnreadCount.current,
            [status]: msgUnreadCount.current[status] + newCount,
          };
          unreadMessages.current = [
            ...unreadMessages.current,
            {...unreadMsgs},
          ];

          dispatch(createConversationsUnreadCount(msgUnreadCount.current));
          dispatch(createConversationsUnreadMsgs(unreadMessages.current));
        }
      }
    });

    // Handle conversation assignment
    socket.on("assignedConversation", (data) => {
      if (data?.firm_id === firm_id) {
        dispatch(createNewMessageEvent(data));
      }
    });

    // Handle delivery reports
    socket.on("newDeliveryReport", (data) => {
      dispatch(createNewMessageEvent({ ...data, type: "dlr" }));
    });

    // Handle disconnects
    socket.on("disconnect", (reason) => {
      console.log("REASON:", reason);
      mixpanel.track("WebSocket Disconnection", { reason });

      if (reason === "io server disconnect") {
        socket.connect(); // Manually reconnect if the server disconnected the client
      }
    });

    // Handle errors
    socket.on("error", (error) => {
      console.log("ERROR:", error);
    });

    // Start interval to keep connection alive
    intervalRef.current = setInterval(() => {
      socket.emit("ping-alive", "hello world");
    }, 58000);

    return () => {
      // Cleanup on unmount
      socket.disconnect();
      clearInterval(intervalRef.current);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAuthToken, dispatch]);
  // WebSocket.io Script Ends Here

  useEffect(() => {
    const intervalTimer = setInterval(() => {
      store.clearEx() // clean up store
    }, 1000);

    return () => clearInterval(intervalTimer);
  }, []);

  return (
    <BrowserRouter>
      <Suspense fallback={<div>...</div>}>
        <Switch>
          <Redirect exact path="/" to="/home" />
          <PublicRoute path="/login" component={Auth} />
          <PublicRoute path="/register" component={Auth} />
          <PublicRoute path="/sign-up" component={Auth} />
          <PublicRoute path="/verify/:token" component={Auth} />
          <PublicRoute path="/auth/confirmation/:token" component={Auth} />
          <PublicRoute path="/auth/reset-password/:token" component={Auth} />
          <PublicRoute path="/feedback/:id" component={Csat} />

          <PrivateRoute exact path="/get-started" component={GetStarted} />
          <PrivateRoute exact path="/get-started/:id" component={GetStarted} />
          <PrivateRoute exact path="/home" component={Home} />
          {conversationsPermissions.read && (
            <PrivateRoute
              exact
              path="/conversations"
              component={Conversations}
            />
          )}
          {conversationsPermissions.read && (
            <PrivateRoute
              path="/conversations/:id/:channel"
              component={Conversations}
            />
          )}
          {conversationsPermissions.read && (
            <PrivateRoute exact path="/activity" component={Mentions} />
          )}
          {conversationsPermissions.read && (
            <PrivateRoute path="/activity/:mentionId" component={Mentions} />
          )}
          {broadcastPermissions.read && (
            <PrivateRoute exact path="/broadcasts" component={Broadcasts} />
          )}
          {broadcastPermissions.read && (
            <PrivateRoute path="/broadcasts/:id" component={BroadcastInfo} />
          )}
          {customersPermissions.read && (
            <PrivateRoute exact path="/customers" component={Customers} />
          )}
          {customersPermissions.read && (
            <PrivateRoute path="/customers/:id" component={CustomerDetails} />
          )}
          {insightsPermissions.read && (
            <PrivateRoute path="/insights" component={Insights} />
          )}
          {insightsPermissions.read && (
            <PrivateRoute
              path="/insights-details/agents/:id"
              component={AgentInsightsDetails}
            />
          )}
          <PrivateRoute path="/settings" component={Settings} />
          <Route path="*" render={() => <Redirect to="/home" />} />
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
};

export default App;
