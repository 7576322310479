import mixpanel from "mixpanel-browser";
import { getRequest, postRequest } from "../../api";

import {
  APP_ONEROUTE_USER,
  APP_ONEROUTE_NEW_USER,
} from "../../utils/constants";

import {
  SIGNUP_LOADER,
  SIGNUP_RESPONSE,
  LOGIN_LOADER,
  LOGIN_RESPONSE,
  LOGIN_RESET,
  FORGOT_PASSWORD_LOADER,
  FORGOT_PASSWORD_RESPONSE,
  FORGOT_PASSWORD_RESET,
  RESET_PASSWORD_LOADER,
  RESET_PASSWORD_RESPONSE,
  RESET_PASSWORD_RESET,
  SET_PASSWORD_LOADER,
  SET_PASSWORD_RESPONSE,
  SET_PASSWORD_RESET,
  SET_ONBOARDING_STEP,
  LOGOUT,
} from "../constants";
import { getRequestError, logoutUser } from "../../utils/functions";

export const signupLoader = (isSigningUp) => ({
  type: SIGNUP_LOADER,
  isSigningUp,
});

export const signupResponse = (signUpOutcome, signUpData, signUpMessage) => ({
  type: SIGNUP_RESPONSE,
  signUpOutcome,
  signUpData,
  signUpMessage,
});

export const loginLoader = (isLoggingIn) => ({
  type: LOGIN_LOADER,
  isLoggingIn,
});

export const loginResponse = (loginOutcome, loginData, loginMessage) => ({
  type: LOGIN_RESPONSE,
  loginOutcome,
  loginData,
  loginMessage,
});

export const loginReset = () => ({
  type: LOGIN_RESET,
});

export const forgotPasswordLoader = (isForgotPasswordLoading) => ({
  type: FORGOT_PASSWORD_LOADER,
  isForgotPasswordLoading,
});

export const forgotPasswordResponse = (
  forgotPasswordOutcome,
  forgotPasswordMessage,
  forgotPasswordEmail
) => ({
  type: FORGOT_PASSWORD_RESPONSE,
  forgotPasswordOutcome,
  forgotPasswordMessage,
  forgotPasswordEmail,
});

export const forgotPasswordReset = () => ({
  type: FORGOT_PASSWORD_RESET,
});

export const resetPasswordLoader = (isResetPasswordLoading) => ({
  type: RESET_PASSWORD_LOADER,
  isResetPasswordLoading,
});

export const resetPasswordResponse = (
  resetPasswordOutcome,
  resetPasswordMessage
) => ({
  type: RESET_PASSWORD_RESPONSE,
  resetPasswordOutcome,
  resetPasswordMessage,
});

export const resetPasswordReset = () => ({
  type: RESET_PASSWORD_RESET,
});

export const setPasswordLoader = (isSetPasswordLoading) => ({
  type: SET_PASSWORD_LOADER,
  isSetPasswordLoading,
});

export const setPasswordResponse = (
  setPasswordOutcome,
  setPasswordMessage
) => ({
  type: SET_PASSWORD_RESPONSE,
  setPasswordOutcome,
  setPasswordMessage,
});

export const setPasswordReset = () => ({
  type: SET_PASSWORD_RESET,
});

export const setOnboardingStep = (onboardingStep) => ({
  type: SET_ONBOARDING_STEP,
  onboardingStep,
});

export const logout = () => ({
  type: LOGOUT,
});

export const fetchConversationCsatStatus = (id) => {
  return async () => {
    try {
      const res = await getRequest({
        url: `/conversations/csat/${id}`,
      });

      const data = res.data?.data;
      return data;
    } catch (error) {
      const message = error?.response?.data?.message;
      console.log(message);
    }
  };
};

export const submitCsatScore = (data) => {
  return async () => {
    try {
      const res = await postRequest({
        url: `/conversations/csat/${data?.id}`,
        data: data?.data,
      });

      const response = res.data;
      return response;
    } catch (error) {
      const message = error?.response?.data?.message;
      console.log(message);
    }
  };
};

export const signUpAction = (data) => {
  return async (dispatch) => {
    dispatch(signupLoader(true));

    try {
      const res = await postRequest({
        url: "/auth/sign-up",
        data,
      });

      dispatch(
        signupResponse(
          "success",
          null,
          "Account created! A verification email has been sent to your mail."
        )
      );
      dispatch(signupLoader(false));

      return res;
    } catch (error) {
      const message = getRequestError(error);

      dispatch(signupResponse("error", null, message));
      dispatch(signupLoader(false));
    }
  };
};

export const confirmEmailAction = (data) => {
  return async (dispatch) => {
    dispatch(signupLoader(true));

    try {
      const res = await postRequest({
        url: "/auth/confirm-email",
        data,
      });

      const user = res.data.data;
      localStorage.setItem(APP_ONEROUTE_NEW_USER, JSON.stringify(user));

      dispatch(signupResponse("success", null, ""));
      dispatch(signupLoader(false));

      return res?.data;
    } catch (error) {
      const message = error?.response?.data?.message;

      dispatch(signupResponse("error", null, message));
      dispatch(signupLoader(false));
    }
  };
};

export const createFirmAction = (data) => {
  return async (dispatch) => {
    dispatch(signupLoader(true));

    try {
      const res = await postRequest({
        url: "/firms",
        token: true,
        data,
      });

      const newlyRegisteredUser = JSON.parse(
        localStorage.getItem(APP_ONEROUTE_NEW_USER)
      );

      const response = res?.data?.data;

      const user = {
        id: newlyRegisteredUser?.user?.id,
        firm_id: response?.id,
      };

      dispatch(signupResponse("success", null, ""));
      dispatch(signupLoader(false));

      mixpanel.alias(user?.id);
      mixpanel.track("Sign up", {
        firm_id: user?.firm_id,
      });
      return res;
    } catch (error) {
      const message = getRequestError(error);

      dispatch(signupResponse("error", null, message));
      dispatch(signupLoader(false));
    }
  };
};

export const getUserLoginInfoAction = () => {
  return async (dispatch) => {
    try {
      const res = await getRequest({
        url: "/auth/current-user",
        token: true,
      });

      localStorage.removeItem(APP_ONEROUTE_NEW_USER);

      var response = res?.data?.data;
      const objToSave = {
        ...response,
        user: {
          ...response?.user,
          firm_onboarding: 1,
        },
      };
      localStorage.setItem(APP_ONEROUTE_USER, JSON.stringify(objToSave));

      return res;
    } catch (error) {
      const message = getRequestError(error);
      console.log(message);
    }
  };
};

export const loginAction = (data) => {
  return async (dispatch) => {
    dispatch(loginReset());
    dispatch(loginLoader(true));

    try {
      const res = await postRequest({
        url: "/auth/sign-in",
        data,
      });
      const user = res.data.data;

      if (user?.user?.firm_id) {
        localStorage.removeItem(APP_ONEROUTE_NEW_USER);
        localStorage.setItem(APP_ONEROUTE_USER, JSON.stringify(user));

        mixpanel.identify(user?.user?.id);
        mixpanel.track("Login", {
          firm_id: user?.user?.firm_id,
        });
        mixpanel.people.set_once({
          first_name: user?.user?.firstName,
          last_name: user?.user?.lastName,
          email: user?.user?.email,
        });
      } else {
        localStorage.setItem(APP_ONEROUTE_NEW_USER, JSON.stringify(user));
      }

      return user;
    } catch (error) {
      const message = getRequestError(error);
      console.log(message, "message");

      dispatch(loginResponse("error", null, message));
      dispatch(loginLoader(false));
    }
  };
};

export const getUserProfileAction = () => {
  return async (dispatch) => {
    try {
      const res = await getRequest({
        url: "/auth/me",
        token: true,
      });

      const oldUserData = JSON.parse(localStorage.getItem(APP_ONEROUTE_USER));
      const newUserData = res.data;
      var userDataToSave = {
        ...oldUserData,
        user: {
          ...newUserData.data,
          firm_onboarding: oldUserData?.user?.firm_onboarding,
        },
      };
      if (newUserData.data) {
        localStorage.setItem(APP_ONEROUTE_USER, JSON.stringify(userDataToSave));
      }

      return newUserData;
    } catch (error) {
      const message = getRequestError(error);
      console.log(message);
    }
  };
};

export const forgotPasswordAction = (data) => {
  return async (dispatch) => {
    dispatch(forgotPasswordReset());
    dispatch(forgotPasswordLoader(true));

    try {
      await postRequest({
        url: "/auth/password-reset-request",
        data,
      });

      dispatch(
        forgotPasswordResponse(
          "success",
          "A reset link has been sent to your mail."
        )
      );
      dispatch(forgotPasswordLoader(false));
    } catch (error) {
      const message = getRequestError(error);

      dispatch(forgotPasswordResponse("error", message));
      dispatch(forgotPasswordLoader(false));
    }
  };
};

export const resetPasswordAction = (data) => {
  return async (dispatch) => {
    dispatch(resetPasswordReset());
    dispatch(resetPasswordLoader(true));

    try {
      await postRequest({
        url: `/auth/reset-password`,
        data,
      });

      dispatch(
        resetPasswordResponse("success", "Password reset successfully!")
      );
      mixpanel.track("Password Reset");
      dispatch(resetPasswordLoader(false));
    } catch (error) {
      const message = getRequestError(error);

      dispatch(resetPasswordResponse("error", message));
      dispatch(resetPasswordLoader(false));
    }
  };
};

export const setPasswordAction = (data) => {
  return async (dispatch) => {
    dispatch(setPasswordReset());
    dispatch(setPasswordLoader(true));

    try {
      await postRequest({
        url: `/auth/confirm`,
        data,
      });

      dispatch(setPasswordResponse("success", "Password set successfully!"));
      dispatch(setPasswordLoader(false));
      return "success";
    } catch (error) {
      const message = getRequestError(error);

      dispatch(setPasswordResponse("error", message));
      dispatch(setPasswordLoader(false));
    }
  };
};

export const logoutAction = () => {
  return async (dispatch) => {
    try {
      await postRequest({
        url: `/auth/sign-out`,
        token: true,
      });

      mixpanel.reset();
      dispatch(logout());
      logoutUser();
    } catch (error) {
      const message = getRequestError(error);
      console.log(message);
    }
  };
};
