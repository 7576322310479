import moment from 'moment'

export class Store {
  store = new Map()

  set(key, value) {
    return this.store.set(key, value)
  }

  has(key) {
    return this.store.has(key)
  }

  clearEx(expiry = 60) {
    const entries = this.store.entries()
    for (const [key, value] of entries) {
      const setTime = moment(value.setTime)
      const currentTime = moment()
      const duration = moment.duration(currentTime.diff(setTime))
      if (duration.asSeconds > expiry) {
        this.store.delete(key)
      }
    }
  }
}