import axios from "axios";

import { APP_ONEROUTE_USER, APP_ONEROUTE_NEW_USER } from "../utils/constants";

export const API = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {},
});

export const getUserDetails = () => {
  return JSON.parse(localStorage.getItem(APP_ONEROUTE_USER)) || null;
};

export const getNewlyRegisteredUserDetails = () => {
  return JSON.parse(localStorage.getItem(APP_ONEROUTE_NEW_USER)) || null;
};

// Add response interceptor for handling token refresh
API.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    // If error is 401 and we haven't tried to refresh token yet
    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        const currentUser = getUserDetails();
        if (currentUser?.refreshToken) {
          // Call your refresh token endpoint
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/auth/refresh`,
            { refreshToken: currentUser.refreshToken }
          );

          const { token, refreshToken } = response?.data?.data;

          // Update stored user details with new tokens
          localStorage.setItem(
            APP_ONEROUTE_USER,
            JSON.stringify({ ...currentUser, token, refreshToken })
          );

          // Retry the original request with new token
          originalRequest.headers.Authorization = `Token token=${token}`;
          return API(originalRequest);
        }
      } catch (refreshError) {
        // Handle refresh token failure (e.g., logout user)
        localStorage.removeItem(APP_ONEROUTE_USER);
        localStorage.removeItem(APP_ONEROUTE_NEW_USER);
        window.location.href = "/login";
        return Promise.reject(refreshError);
      }
    }
    return Promise.reject(error);
  }
);

export const getRequest = async ({ url, params, token }) => {
  const currentUser = getUserDetails();
  const newlyRegisteredUser = getNewlyRegisteredUserDetails();

  const userToUse = currentUser || newlyRegisteredUser;

  var request = {
    url,
    method: "get",
    params,
  };

  if (token && userToUse?.token) {
    request.headers = { Authorization: `Token token=${userToUse.token}` };
  }

  return await API(request);
};

export const postRequest = async ({ url, params, data, token, formData }) => {
  const currentUser = getUserDetails();
  const newlyRegisteredUser = getNewlyRegisteredUserDetails();

  const userToUse = currentUser ? currentUser : newlyRegisteredUser;

  var request = {
    url,
    method: "post",
    params,
    data,
  };

  if (token && userToUse && userToUse.token) {
    request["headers"] = { Authorization: `Token token=${userToUse.token}` };
  }

  if (formData) {
    request.headers = {
      ...request.headers,
      "Content-Type": "multipart/form-data",
    };
  }

  const requestResponse = await API(request);

  return requestResponse;
};

export const patchRequest = async ({ url, params, data, token }) => {
  const currentUser = getUserDetails();

  var request = {
    url,
    method: "patch",
    params,
    data,
  };

  if (token && currentUser && currentUser.token) {
    request["headers"] = { Authorization: `Token token=${currentUser.token}` };
  }

  const requestResponse = await API(request);

  return requestResponse;
};

export const putRequest = async ({ url, params, data, token }) => {
  const currentUser = getUserDetails();

  var request = {
    url,
    method: "put",
    params,
    data,
  };

  if (token && currentUser && currentUser.token) {
    request["headers"] = { Authorization: `Token token=${currentUser.token}` };
  }

  const requestResponse = await API(request);

  return requestResponse;
};

export const deleteRequest = async ({ url, params, data, token }) => {
  const currentUser = getUserDetails();

  var request = {
    url,
    method: "delete",
    params,
    data,
  };

  if (token && currentUser && currentUser.token) {
    request["headers"] = { Authorization: `Token token=${currentUser.token}` };
  }

  const requestResponse = await API(request);

  return requestResponse;
};
