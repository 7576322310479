if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register("/firebase-messaging-sw.js")
    .then((registration) => {
      // Ensures the service worker is ready before proceeding
      return navigator.serviceWorker.ready;
    })
    .then((registration) => {
      // Request notification permission
      return Notification.requestPermission();
    })
    .then((permission) => {
      if (permission !== "granted") {
        console.warn("Notification permission denied.");
      }
    })
    .catch((error) => console.error("Service Worker Error:", error));
}
