import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { patchRequest, deleteRequest, getRequest } from "../../api";

import { ChatContainer, ConversationSections, ConversationLists } from "./";

import {
  getConversationsCountAction,
  getConversationsAction,
  createConversationsUnreadCount,
  createConversationsUnreadMsgs,
} from "../../redux/actions/Conversations";
import { getFirmChannelsAction } from "../../redux/actions/Channels";
import { getTagsAction } from "../../redux/actions/Tags";
import { getSavedResponsesAction } from "../../redux/actions/SavedResponses";
import { getSignatureAction } from "../../redux/actions/Signature";

import { conversationsPermissions } from "../../utils/permissions";
import { getUserDetails, getRequestError } from "../../utils/functions";
import { PAGE_SIZE } from "../../utils/constants";

class Conversations extends Component {
  state = {
    selectedSection: "Assigned to me",
    query: {
      "page[size]": PAGE_SIZE,
      "page[number]": 1,
      sortBy: "asc",
    },
    newDlr: null,
    selectedConversation: {},
    updatedPreview: [],
    acceptedConversation: {},
    checkingExistingCustomer: false,
    isNewConversationOpen: false,
    isLoadingConversationDetails: false,
    isSearchActive: false,
    allAgents: [],
  };

  toggleIsSearchActive = (value) => {
    this.setState({
      isSearchActive: value,
    });
  };

  searchConversations = async () => {
    const { searchValue, searchType } = this.state;

    this.setState({
      isSearchOptionsActive: false,
    });

    const newSearchValue = searchValue.replace(`${searchType}: `, "");

    if (newSearchValue) {
      this.setState({
        isSearchingConversations: true,
      });

      try {
        const res = await getRequest({
          url: `conversations?phone=${encodeURIComponent(newSearchValue)}`,
          token: true,
        });

        this.setState({
          conversationResults: res.data.data,
          isSearchingConversations: false,
        });
      } catch (error) {
        console.log(error);

        this.setState({
          isSearchingConversations: false,
        });
      }
    }
  };

  selectSection = (selectedSection) => {
    this.props.history.push(`/conversations`);

    this.setState(
      {
        query: {
          ...this.state.query,
          "page[number]": 1,
        },
        selectedSection,
        selectedConversation: {},
        isLoadingConversationDetails: false,
      },
      () => {
        if (selectedSection === "Open") {
          this.getOpenConversations();
        } else if (selectedSection === "In-progress") {
          this.getInProgressConversations();
        } else if (selectedSection === "Awaiting Response") {
          this.getAwaitingResponseConversations();
        } else if (selectedSection === "Queue") {
          this.getQueueConversations();
        } else if (selectedSection === "Resolved") {
          this.getResolvedConversations();
        } else if (selectedSection === "All Conversations") {
          this.getAllConversations();
        }
      }
    );
  };

  selectConversation = ({ id, customer_id, channel }) => {
    if (customer_id && this.state?.selectedConversation?.id !== customer_id) {
      this.setState(
        {
          query: {
            ...this.state.query,
            "page[number]": 1,
          },
          selectedConversation: {},
        },
        () => {
          const { selectedConversation } = this.state;

          if (selectedConversation.customer_id !== customer_id) {
            this.props.history.push(
              `/conversations/${customer_id}/${channel?.id}`
            );
          }

          this.updateReadConvoCountAndMsgs(id);
        }
      );
    }
  };

  getConversation = async (id, channel, noLoader, paginate, refreshConvo) => {
    !noLoader &&
      this.setState({
        isLoadingConversationDetails: true,
        selectedConversation: {},
      });

    const queryPageNumber = this.state.query["page[number]"];
    this.setState({
      query: {
        ...this.state.query,
        "page[number]": paginate ? queryPageNumber + 1 : 1,
      },
    });

    if (id) await this.getConversationsById(id, channel);
    if (id) await this.getConversationMessagesUp(id, true, null, channel);
    if (id) refreshConvo && this.refetchConversationsByStatus();
  };

  getConversationsById = async (id, channel) => {
    const currentUser = getUserDetails();

    if (id) {
      try {
        const res = await getRequest({
          url: `conversations/${id}`,
          params: { channel_id: channel },
          token: true,
        });

        const conversationData = res.data?.data || {};

        this.setState({
          selectedConversation: {
            ...conversationData,
            channel_id: channel,
            Messages: this.state.selectedConversation?.Messages || [],
            metadata: this.state.selectedConversation?.metadata || null,
          },
          selectedSection:
            this.state.selectedSection === "All Conversations"
              ? "All Conversations"
              : conversationData?.status === "in-queue"
              ? "Queue"
              : conversationData?.agent_id !== currentUser.id &&
                this.state.selectedSection !== "Queue"
              ? "All Conversations"
              : conversationData?.status === "open"
              ? "Open"
              : conversationData?.status === "in-progress"
              ? "In-progress"
              : conversationData?.status === "awaiting-customer-response"
              ? "Awaiting Response"
              : "Resolved",
        });
      } catch (error) {
        console.log(error);

        this.setState({
          isLoadingConversationDetails: false,
        });
      }
    }
  };

  getConversationMessagesUp = async (id, noPaginate, rowId, channel) => {
    const { selectedConversation, metadataId } = this.state;
    const {
      isLastPageOfPrevMessages,
      channel_id,
      metadata,
      savedSearchedMsgRowId,
    } = selectedConversation;

    const searchedMsgRowId = this.props.location?.search?.replace(
      "?msgRowId=",
      ""
    );
    var searchedMsgRowIdToUse = searchedMsgRowId;
    if (searchedMsgRowId === savedSearchedMsgRowId) {
      searchedMsgRowIdToUse = null;
    }

    let msgRowId = searchedMsgRowIdToUse || rowId || null;
    const channelToUse = channel ? channel : channel_id;

    const oldMessages = selectedConversation?.Messages || [];

    const lastRowId = oldMessages?.[oldMessages?.length - 1]?.row_id;

    if (id && !isLastPageOfPrevMessages && channel_id) {
      try {
        const res = await getRequest({
          url: `conversations/${id}/messages`,
          token: true,
          params: {
            channel_id: channelToUse,
            "page[size]": PAGE_SIZE,
            lastMessageAt: msgRowId ? msgRowId : noPaginate ? null : lastRowId,
          },
        });

        const data = res.data?.data || [];

        oldMessages?.splice(oldMessages?.length - 1, 1); // remove the last message from the oldMessages.

        const firstConvoId = data?.map((x) => x?.conversation_id)?.[0];
        if (!metadata && metadataId !== firstConvoId) {
          this.setState(
            {
              metadataId: firstConvoId,
            },
            () => this.getMessagesMetaData(firstConvoId)
          );
        }

        this.setState(
          {
            selectedConversation: {
              ...selectedConversation,
              Messages: noPaginate ? data : [...oldMessages, ...data],
              isLastPageOfPrevMessages: data?.length < 10 ? true : false,
              isLastPageOfReceMessages: msgRowId ? false : true,
              savedSearchedMsgRowId: searchedMsgRowId,
            },
            isLoadingConversationDetails: false,
          },
          () => {
            if (msgRowId) {
              this.setState(
                {
                  isLoadingConversationMessagesUp: true,
                },
                () => {
                  setTimeout(() => {
                    document?.getElementById(data[0]?.id)?.scrollIntoView({
                      behavior: "smooth",
                      block: "nearest",
                      inline: "start",
                    });

                    this.setState({
                      isLoadingConversationMessagesUp: false,
                    });
                  }, 100);
                  clearTimeout();
                }
              );
            }
          }
        );
      } catch (error) {
        console.log(error);

        this.setState({
          isLoadingConversationDetails: false,
        });
      }
    }
  };

  getConversationMessagesDown = async (id, noPaginate, msgRowId) => {
    const { selectedConversation, isLoadingConversationMessagesUp } =
      this.state;
    const { isLastPageOfReceMessages, channel_id } = selectedConversation;

    const oldMessages = selectedConversation?.Messages || [];

    const lastRowId = oldMessages?.[0]?.row_id;

    if (
      id &&
      (msgRowId || !isLastPageOfReceMessages) &&
      channel_id &&
      !isLoadingConversationMessagesUp
    ) {
      try {
        const res = await getRequest({
          url: `conversations/${id}/messages`,
          token: true,
          params: {
            channel_id,
            "page[size]": PAGE_SIZE,
            direction: "up",
            lastMessageAt: msgRowId ? msgRowId : noPaginate ? null : lastRowId,
          },
        });

        const data = res.data?.data?.reverse() || [];

        oldMessages?.splice(0, 1); // remove the first message from the oldMessages.

        this.setState({
          selectedConversation: {
            ...this.state.selectedConversation,
            Messages: noPaginate ? data : [...data, ...oldMessages],
            isLastPageOfReceMessages: data?.length < 10 ? true : false,
          },
          isLoadingConversationDetails: false,
        });
      } catch (error) {
        console.log(error);

        this.setState({
          isLoadingConversationDetails: false,
        });
      }
    }
  };

  getMessagesMetaData = async (id) => {
    if (id) {
      try {
        const res = await getRequest({
          url: `conversations/${id}/metadata`,
          token: true,
        });

        const data = res.data?.data || null;

        this.setState({
          selectedConversation: {
            ...this.state.selectedConversation,
            metadata: data,
          },
          metadataId: id,
        });
      } catch (error) {
        console.log(error);
      }
    }
  };

  refetchConversationsByStatus = () => {
    const currentUser = getUserDetails();
    const { getConversationsCountAction } = this.props;
    const { selectedConversation } = this.state;

    getConversationsCountAction();

    if (selectedConversation?.status === "open") this.getOpenConversations();
    if (selectedConversation?.status === "in-progress")
      this.getInProgressConversations();
    if (selectedConversation?.status === "awaiting-customer-response")
      this.getAwaitingResponseConversations();
    if (selectedConversation?.status === "in-queue")
      this.getQueueConversations();
    if (selectedConversation?.status === "closed")
      this.getResolvedConversations();
    if (selectedConversation?.agent_id !== currentUser?.id)
      this.getAllConversations();
  };

  acceptConversation = async (acceptedConversation) => {
    const { getConversationsCountAction } = this.props;
    const { customer_id, channel } = acceptedConversation;

    if (acceptedConversation.id && customer_id) {
      this.setState({
        isLoadingConversationDetails: true,
      });

      try {
        await patchRequest({
          url: `conversations/${acceptedConversation.id}/assign`,
          token: true,
        });

        this.setState(
          {
            isLoadingConversationDetails: false,
          },
          () => {
            getConversationsCountAction();
            this.updateReadConvoCountAndMsgs(acceptedConversation.id);

            this.selectSection("Open");
            this.props.history.push(
              `/conversations/${customer_id}/${channel?.id}`
            );
          }
        );
      } catch (error) {
        const message = getRequestError(error);
        console.log(message, error);

        this.setState({
          isLoadingConversationDetails: false,
        });
      }
    }
  };

  closeConversation = async (id, customer_id, channel_id) => {
    const { getConversationsCountAction } = this.props;

    if (id || (customer_id && channel_id)) {
      this.setState({
        isClosingConversation: true,
      });

      try {
        if (this.state.selectedSection === "Resolved" && id) {
          await patchRequest({
            url: `conversations/${id}`,
            token: true,
            data: {
              status: "in-progress",
            },
          });
        } else {
          await deleteRequest({
            url: `conversations/${customer_id}/${channel_id}`,
            token: true,
          });
        }

        getConversationsCountAction();
        this.refetchConversationsByStatus();
        this.setState(
          {
            isClosingConversation: false,
            selectedConversation: {},
          },
          () => {
            this.props.history.push(`/conversations`);
            this.selectSection("Resolved");
          }
        );
      } catch (error) {
        const message = getRequestError(error);
        console.log(message, error);

        this.setState({
          isClosingConversation: false,
        });
      }
    }
  };

  returnToQueue = async (selectedConversation) => {
    const { getConversationsCountAction } = this.props;

    this.setState({
      isLoadingConversationDetails: true,
    });

    if (selectedConversation?.id) {
      try {
        await patchRequest({
          url: `conversations/${selectedConversation?.id}`,
          token: true,
          data: {
            status: "in-queue",
          },
        });

        getConversationsCountAction();
        this.getQueueConversations();

        this.setState({
          isLoadingConversationDetails: false,
          selectedConversation: {},
          selectedSection: "Queue",
        });

        this.props.history.push(`/conversations`);
      } catch (error) {
        const message = getRequestError(error);
        console.log(message, error);

        this.setState({
          isLoadingConversationDetails: false,
        });
      }
    }
  };

  reassignConversation = async (conversationId, agent_id) => {
    const { getConversationsCountAction } = this.props;

    if (conversationId) {
      try {
        await patchRequest({
          url: `conversations/${conversationId}/assign`,
          token: true,
          data: {
            agent_id,
          },
        });

        getConversationsCountAction();
        this.refetchConversationsByStatus();

        this.setState({
          selectedConversation: {},
        });

        this.props.history.push(`/conversations`);
      } catch (error) {
        const message = getRequestError(error);
        console.log(message, error);
      }
    }
  };

  updatePreview = (data) => {
    const allPreviews = this.state.updatedPreview;
    const indexOfNewPreview = this.state.updatedPreview?.findIndex(
      (x) => x?.conversationId === data?.conversationId
    );

    if (indexOfNewPreview >= 0) {
      allPreviews[indexOfNewPreview].preview = data?.preview;
      this.setState({
        updatedPreview: allPreviews,
      });
    } else {
      this.setState({
        updatedPreview: [...allPreviews, data],
      });
    }
  };

  updateSelectedConversationMessages = (incomingMsg) => {
    const { selectedConversation } = this.state;

    if (selectedConversation?.id) {
      this.setState({
        selectedConversation: {
          ...selectedConversation,
          Messages: [incomingMsg, ...selectedConversation?.Messages],
        },
      });
    }
  };

  openNewConversation = () => {
    this.setState({
      isNewConversationOpen: true,
    });
  };

  closeNewConversation = () => {
    this.setState({
      isNewConversationOpen: false,
    });
  };

  startConversation = async ({ customer, channel }) => {
    this.setState({
      checkingExistingCustomer: true,
    });

    const url = customer?.id
      ? `/conversations/search?customer_id=${customer?.id}&channel_id=${channel?.id}`
      : `/conversations/search?phone=${encodeURIComponent(
          customer?.phone
        )}&channel_id=${channel?.id}`;

    try {
      const res = await getRequest({
        url,
        token: true,
      });

      const conversations = res.data.data;

      const unclosedConversation = customer?.id
        ? conversations?.filter((x) => x?.status !== "closed")
        : conversations?.filter(
            (x) =>
              x?.customer?.phone?.includes(customer?.phone) &&
              x?.status !== "closed"
          );

      if (
        unclosedConversation?.length &&
        unclosedConversation?.[0]?.Messages?.length
      ) {
        const { customer_id, Channel } = unclosedConversation[0];

        this.setState(
          {
            checkingExistingCustomer: false,
          },
          () => {
            this.closeNewConversation();
            this.props.history.push(
              `/conversations/${customer_id}/${Channel?.id}`
            );
          }
        );
      } else {
        const newConvo = {
          Messages: [],
          customer,
          Channel: channel,
          updatedAt: new Date().toISOString(),
        };

        this.setState(
          {
            selectedConversation: newConvo,
            checkingExistingCustomer: false,
          },
          () => this.closeNewConversation()
        );
      }
    } catch (error) {
      console.log(error);

      this.setState({
        checkingExistingCustomer: false,
      });
    }
  };

  getOpenConversations = (noReset, paginate) => {
    const { getConversationsAction } = this.props;
    const { id } = getUserDetails();

    this.setState(
      {
        query: {
          ...this.state.query,
          sortBy: "desc",
          "page[number]": paginate ? this.state.query["page[number]"] + 1 : 1,
        },
        isLoadingConversationDetails: false,
      },
      () =>
        getConversationsAction(
          {
            ...this.state.query,
            agent_id: id,
            status: "open",
          },
          "openConversations",
          noReset,
          paginate
        )
    );
  };

  getInProgressConversations = (noReset, paginate) => {
    const { getConversationsAction } = this.props;
    const { id } = getUserDetails();

    this.setState(
      {
        query: {
          ...this.state.query,
          sortBy: "desc",
          "page[number]": !paginate ? 1 : this.state.query["page[number]"] + 1,
        },
        isLoadingConversationDetails: false,
      },
      () => {
        getConversationsAction(
          {
            ...this.state.query,
            agent_id: id,
            status: "in-progress",
          },
          "inProgressConversations",
          noReset,
          paginate
        );
      }
    );
  };

  getAwaitingResponseConversations = (noReset, paginate) => {
    const { getConversationsAction } = this.props;
    const { id } = getUserDetails();

    this.setState(
      {
        query: {
          ...this.state.query,
          sortBy: "desc",
          "page[number]": paginate ? this.state.query["page[number]"] + 1 : 1,
        },
        isLoadingConversationDetails: false,
      },
      () =>
        getConversationsAction(
          {
            ...this.state.query,
            agent_id: id,
            status: "awaiting-customer-response",
          },
          "awaitingResponseConversations",
          noReset,
          paginate
        )
    );
  };

  getQueueConversations = (noReset, paginate, sortBy, channelId) => {
    const { getConversationsAction } = this.props;

    this.setState(
      {
        query: {
          ...this.state.query,
          sortBy: sortBy || this.state.query.sortBy,
          "page[number]": paginate ? this.state.query["page[number]"] + 1 : 1,
        },
        isLoadingConversationDetails: false,
      },
      () =>
        getConversationsAction(
          {
            ...this.state.query,
            status: "in-queue",
            channel_id: channelId || null,
          },
          "queueConversations",
          noReset,
          paginate
        )
    );
  };

  getResolvedConversations = (noReset, paginate) => {
    const { getConversationsAction } = this.props;
    const { id } = getUserDetails();

    this.setState(
      {
        query: {
          ...this.state.query,
          sortBy: "desc",
          "page[number]": paginate ? this.state.query["page[number]"] + 1 : 1,
        },
        isLoadingConversationDetails: false,
      },
      () =>
        getConversationsAction(
          {
            ...this.state.query,
            agent_id: id,
            status: "closed",
          },
          "resolvedConversations",
          noReset,
          paginate
        )
    );
  };

  getAllConversations = (noReset, paginate) => {
    const { getConversationsAction } = this.props;

    if (conversationsPermissions?.viewAll) {
      this.setState(
        {
          query: {
            ...this.state.query,
            sortBy: "desc",
            "page[number]": paginate ? this.state.query["page[number]"] + 1 : 1,
          },
          isLoadingConversationDetails: false,
        },
        () =>
          getConversationsAction(
            {
              ...this.state.query,
              status: null,
            },
            "allConversations",
            noReset,
            paginate
          )
      );
    }
  };

  getAllUsers = async () => {
    try {
      const res = await getRequest({
        url: "users?status=confirmed",
        token: true,
      });

      this.setState({
        agentsData: res.data.data,
        allAgents: res.data.data.filter(({ roleName }) => roleName === "agent"),
      });
    } catch (error) {
      console.log(error);
    }
  };

  updateReadConvoCountAndMsgs = (id) => {
    const {
      createConversationsUnreadCount,
      createConversationsUnreadMsgs,
      conversationsUnreadCount,
      conversationsUnreadMsgs,
    } = this.props;

    let unreadMessages = conversationsUnreadMsgs;
    let unreadCount = conversationsUnreadCount;

    const selectedUnread = unreadMessages?.filter((msg) => msg?.id === id);
    unreadMessages = unreadMessages?.filter((msg) => msg?.id !== id);

    const section = selectedUnread?.[0]?.status;
    unreadCount = {
      ...conversationsUnreadCount,
      [section]: conversationsUnreadCount[section] - selectedUnread?.length,
    };

    createConversationsUnreadMsgs(unreadMessages);
    createConversationsUnreadCount(unreadCount);
  };

  handleSetNewDlr = (data) => {
    this.setState({ newDlr: data });
  };

  componentDidMount() {
    const {
      getConversationsCountAction,
      getFirmChannelsAction,
      getSavedResponsesAction,
      getSignatureAction,
      match,
    } = this.props;
    const { id, channel } = match.params;

    getConversationsCountAction();
    getSavedResponsesAction();
    getSignatureAction();
    getFirmChannelsAction();
    id && this.getConversation(id, channel, false, false, true);
  }

  componentDidUpdate(prevProps) {
    const { selectedConversation } = this.state;
    const { Customer, Channel } = selectedConversation;
    const { match, location } = this.props;
    const { id, channel } = match.params;

    if (
      selectedConversation?.id &&
      ((!id && Customer?.id) || (!channel && Channel?.id))
    ) {
      this.props.history.push(`/conversations/${Customer?.id}/${Channel?.id}`);
    } else if (
      prevProps.match.params?.id !== id ||
      prevProps.match.params?.channel !== channel
    ) {
      this.getConversation(id, channel);
    }

    const allCaseExceptMsgIdMatches =
      prevProps.match.params?.id === id &&
      prevProps.match.params?.channel === channel &&
      prevProps.location.search !== location.search;

    if (allCaseExceptMsgIdMatches) {
      let msgRowId =
        this.props.location?.search?.replace("?msgRowId=", "") || null;

      if (msgRowId) {
        this.getConversationMessagesUp(id, true, msgRowId, channel);
      }
    }
  }

  render() {
    const {
      selectedSection,
      selectedConversation,
      acceptedConversation,
      updatedPreview,
      newDlr,
      isSearchActive,
      newlyAssigned,
      checkingExistingCustomer,
      isNewConversationOpen,
      isLoadingConversationDetails,
      isClosingConversation,
      selectedConversationCurrentPage,
      selectedConversationTotalPage,
    } = this.state;
    const { agentsData, getConversationsCountAction } = this.props;

    const allAgents = agentsData?.filter(
      ({ roleName }) => roleName === "agent"
    );

    return (
      <div className="conversations-container">
        <ConversationSections
          selectedSection={selectedSection}
          selectSection={this.selectSection}
          selectedConversation={selectedConversation}
          isSearchActive={isSearchActive}
          toggleIsSearchActive={this.toggleIsSearchActive}
        />
        <ConversationLists
          selectedSection={selectedSection}
          newDlr={newDlr}
          updatePreview={this.updatePreview}
          selectConversation={this.selectConversation}
          selectedConversation={selectedConversation}
          acceptConversation={this.acceptConversation}
          acceptedConversation={acceptedConversation}
          updatedPreview={updatedPreview}
          openNewConversation={this.openNewConversation}
          isSearchActive={isSearchActive}
          channelsData={this.props?.firmChannelsData}
          getOpenConversations={this.getOpenConversations}
          getInProgressConversations={this.getInProgressConversations}
          getAwaitingResponseConversations={
            this.getAwaitingResponseConversations
          }
          getQueueConversations={this.getQueueConversations}
          getResolvedConversations={this.getResolvedConversations}
          getAllConversations={this.getAllConversations}
          refetchConversationsByStatus={this.refetchConversationsByStatus}
        />
        <ChatContainer
          selectedSection={selectedSection}
          selectedConversation={selectedConversation}
          updateSelectedConversationMessages={
            this.updateSelectedConversationMessages
          }
          newlyAssigned={newlyAssigned}
          checkingExistingCustomer={checkingExistingCustomer}
          closeConversation={this.closeConversation}
          isClosingConversation={isClosingConversation}
          updatePreview={this.updatePreview}
          newDlr={newDlr}
          setNewDlr={this.handleSetNewDlr}
          isNewConversationOpen={isNewConversationOpen}
          closeNewConversation={this.closeNewConversation}
          startConversation={this.startConversation}
          isLoadingConversationDetails={isLoadingConversationDetails}
          selectedConversationCurrentPage={selectedConversationCurrentPage}
          selectedConversationTotalPage={selectedConversationTotalPage}
          getConversation={this.getConversation}
          getConversationsById={this.getConversationsById}
          getConversationMessagesUp={this.getConversationMessagesUp}
          getConversationMessagesDown={this.getConversationMessagesDown}
          metadataId={this.state.metadataId}
          getMessagesMetaData={this.getMessagesMetaData}
          getOpenConversations={this.getOpenConversations}
          getInProgressConversations={this.getInProgressConversations}
          getAwaitingResponseConversations={
            this.getAwaitingResponseConversations
          }
          getQueueConversations={this.getQueueConversations}
          getResolvedConversations={this.getResolvedConversations}
          getAllConversations={this.getAllConversations}
          getConversationsCountAction={() => getConversationsCountAction()}
          returnToQueue={this.returnToQueue}
          reassignConversation={this.reassignConversation}
          allUsers={agentsData}
          allAgents={allAgents}
          channelsData={this.props?.firmChannelsData}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ auth, conversations, agents, channels }) => ({
  ...auth,
  ...conversations,
  ...agents,
  ...channels,
});

export default withRouter(
  connect(mapStateToProps, {
    getConversationsCountAction,
    getConversationsAction,
    getFirmChannelsAction,
    getTagsAction,
    getSavedResponsesAction,
    getSignatureAction,
    createConversationsUnreadCount,
    createConversationsUnreadMsgs,
  })(Conversations)
);
