import React, { useState, useEffect, Suspense, lazy } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { withRouter, Link, useParams } from "react-router-dom";
import Select from "react-select";
import classNames from "classnames";
import mixpanel from "mixpanel-browser";

import {
  createNewMessageEvent,
  postValidateChannelNumberOrIdAction,
} from "../../../redux/actions/Conversations";
import { getCustomersAction } from "../../../redux/actions/Customers";

import { ConvoSidebar } from "./";
import { Integrations } from "./";
import { Button } from "../../../components/FormElements";
import Modal from "../../../components/Modal";
import LottieAnimation from "../../../components/LottieWeb";

import { WhatsApp, Queue, AngleLeft } from "../../../assets/vectors";

import Avatar from "../../../components/Avatar";
import Toaster from "../../../components/Toaster";

import { emptyStates } from "../data";
import TicketForm from "./TicketForm";
import { getInitials, capitalize } from "../../../utils/functions";
import CustomerInfo from "./CustomerInfo";

const Chats = lazy(() => import("./Chats"));

const ChatContainer = ({
  getConversationsById,
  selectedSection,
  selectedConversation,
  updateSelectedConversationMessages,
  newlyAssigned,
  checkingExistingCustomer,
  closeConversation,
  isClosingConversation,
  selectedConversationCurrentPage,
  selectedConversationTotalPage,
  updatePreview,
  newDlr,
  setNewDlr,
  closeNewConversation,
  isNewConversationOpen,
  startConversation,
  isLoadingConversationDetails,
  getConversation,
  getConversationMessagesUp,
  getConversationMessagesDown,
  metadataId,
  getMessagesMetaData,
  getOpenConversations,
  getInProgressConversations,
  getAwaitingResponseConversations,
  getQueueConversations,
  getAllConversations,
  getConversationsCountAction,
  returnToQueue,
  reassignConversation,
  allUsers,
  allAgents,
  customersData,
  channelsData,
}) => {
  const dispatch = useDispatch();
  const { newMessageEvent } = useSelector((state) => state.conversations);

  const { id, channel } = useParams();
  const [newRecipient, setNewRecipient] = useState("");
  const [recipientSelected, selectRecipient] = useState(false);
  const [isValidating, setIsValidating] = useState(false);
  const [isOpeningConvo, setIsOpeningConvo] = useState(false);
  const [isTicketFormOpen, toggleTicketForm] = useState(false);
  const [ticketFormData, patchTicketFormData] = useState(null);
  const [integrationInfo, setIntegrationInfo] = useState("");
  const [selectedChannel, setSelectedChannnel] = useState({
    value: "",
    label: "Select...",
  });
  const [showConvoHistory, setShowConvoHistory] = useState(false);
  const [displaySidebar, setDisplaySidebar] = useState(false);
  const [sidebarContent, setSidebarContent] = useState(null);
  const [toaster, setToaster] = useState({});

  const emptyStateContent =
    emptyStates.find(({ type }) => type === selectedSection) || {};

  const closeModal = () => {
    setNewRecipient("");
    selectRecipient(false);
    closeNewConversation();
  };

  const scrollChatContainerToBottom = () => {
    const chatContainer = document.getElementById("chat-content-container");
    if (chatContainer) {
      chatContainer.scrollTop = chatContainer.scrollHeight;
    }
  };

  // New Message Action Listener - Starts Here
  useEffect(() => {
    if (newMessageEvent) {
      const newMsg = newMessageEvent;
      dispatch(createNewMessageEvent(null));

      if (newMsg?.conversation || newMsg?.id || newMsg?.type) {
        newMessageAction(newMsg);

        if (newMsg?.conversation) {
          mixpanel.track("Received New Message", {
            channel: newMsg?.conversation?.Channel?.medium,
          });
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newMessageEvent]);
  // New Message Action Listener - Ends Here

  const newMessageAction = async (data) => {
    if (data?.type === "dlr") {
      setNewDlr(data);
    } else if (data?.conversation) {
      setNewDlr(null);

      const { message, conversation } = data;

      // Update Conversation List Preview
      updatePreview({
        conversationId: conversation?.id,
        preview: {
          content: message?.content,
          contentType: message?.contentType,
        },
      });

      // If Conversation is currently selected, Update Conversation Messages & Re-fetch Conversation
      if (
        id === conversation?.customer_id &&
        channel === conversation.channel_id
      ) {
        const messageExists = selectedConversation?.Messages?.find(
          (x) => x?.externalId === message?.externalId
        );

        if (!messageExists) {
          updateSelectedConversationMessages({
            ...message,
            id: message?.externalId,
          });
          setTimeout(() => {
            scrollChatContainerToBottom();
          }, 50);

          getConversation(
            conversation?.customer_id,
            conversation?.channel_id,
            true
          );
        }
      }

      // Fetch Conversations
      if (conversation?.status === "open") {
        getOpenConversations(true);
        getQueueConversations(true);
      }
      if (conversation?.status === "in-progress")
        getInProgressConversations(true);
      if (conversation?.status === "awaiting-customer-response")
        getAwaitingResponseConversations(true);
      if (conversation?.status === "in-queue") getQueueConversations(true);

      // Fetch All Conversations
      getAllConversations(true);

      // Fetch Conversations Count
      getConversationsCountAction();
    }
  };

  const handleToggleTicketForm = (integration) => {
    setIntegrationInfo(integration);
    toggleTicketForm(true);
  };

  const handleChangeChannel = (selectedChannel) => {
    setSelectedChannnel(selectedChannel);
  };

  const handleOnKeyDown = (e) => {
    if (e.key === "Enter" && newRecipient?.phone) {
      selectRecipient(true);
    }
  };

  useEffect(() => {
    if (newRecipient?.phone?.length > 0) {
      dispatch(
        getCustomersAction({
          searchQuery: newRecipient?.phone?.toLowerCase(),
          "page[size]": 50,
          "page[number]": 1,
          status: "confirmed",
        })
      );
    } else {
      dispatch(
        getCustomersAction({
          "page[size]": 50,
          "page[number]": 1,
          status: "confirmed",
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newRecipient?.phone]);

  const validateNumberOrId = () => {
    if (newRecipient?.phone && selectedChannel?.value?.medium) {
      if (selectedChannel?.value?.medium?.includes("SMS")) {
        validateNumberOnSuccess();
      } else {
        setIsValidating(true);
        dispatch(
          postValidateChannelNumberOrIdAction({
            channel: selectedChannel?.value?.medium.toLowerCase(),
            body: {
              number: newRecipient?.phone,
            },
          })
        ).then((res) => {
          setIsValidating(false);

          if (res?.success === true) {
            validateNumberOnSuccess(res);
          } else {
            openToaster("error", "Invalid Whatsapp number");
          }
        });
      }
    }
  };

  const validateNumberOnSuccess = (res) => {
    setIsOpeningConvo(true);

    openToaster("success", res?.message || "Success");
    startConversation({
      customer: newRecipient,
      channel: selectedChannel?.value,
    });

    setNewRecipient("");
    selectRecipient(false);
    setSelectedChannnel({
      value: "",
      label: "Select...",
    });
  };

  const openConvoHistory = () => {
    setDisplaySidebar(false);
    setSidebarContent(null);
    setShowConvoHistory(true);
  };

  const closeConvoHistory = () => {
    setShowConvoHistory(false);
  };

  const openToaster = (status, content) => {
    setToaster({
      open: "open",
      status,
      content,
    });
  };

  const closeToaster = () => {
    setToaster({
      ...toaster,
      open: "closed",
    });
  };

  const chatContacts = customersData?.map((x) => x) || [];
  const map = chatContacts.reduce((p, c) => {
    let char = c?.name.charAt(0).toUpperCase();
    p[char] = [].concat(p[char] || [], c);
    return p;
  }, {});

  const customersContact = Object.keys(map).map((k) => ({
    letter: k,
    list: map[k],
  }));

  const activeWhatsappChannels = channelsData?.filter(
    (x) => x?.status === "ACTIVE" && x?.medium === "WHATSAPP"
  );
  const activeSmsChannels = channelsData?.filter(
    (x) => x?.status === "ACTIVE" && x?.medium === "SMS-TW"
  );
  const allActiveChannels = [...activeWhatsappChannels, ...activeSmsChannels];

  const channelOptions = allActiveChannels?.map((x) => ({
    label: capitalize(`${x?.medium} - ${x?.name || x?.phone}`),
    value: {
      id: x?.id,
      medium: x?.medium,
      name: x?.name || x?.phone,
    },
  }));

  return (
    <>
      <Suspense fallback={<div>...</div>}>
        <div className="chat-container">
          {isLoadingConversationDetails ? (
            <div className="loader-container text-center">
              <LottieAnimation
                path={require("../../../assets/jsons/loader.json")}
                autoplay
              />
            </div>
          ) : Object.keys(selectedConversation)?.length > 1 ? (
            <>
              <Chats
                selectedSection={selectedSection}
                selectedConversation={selectedConversation}
                newlyAssigned={newlyAssigned}
                closeConversation={closeConversation}
                isClosingConversation={isClosingConversation}
                updatePreview={updatePreview}
                isNewConversationOpen={isNewConversationOpen}
                closeNewConversation={closeModal}
                returnToQueue={returnToQueue}
                reassignConversation={reassignConversation}
                isLoadingConversationDetails={isLoadingConversationDetails}
                selectedConversationCurrentPage={
                  selectedConversationCurrentPage
                }
                selectedConversationTotalPage={selectedConversationTotalPage}
                getConversation={getConversation}
                getConversationsById={getConversationsById}
                getConversationMessagesUp={getConversationMessagesUp}
                getConversationMessagesDown={getConversationMessagesDown}
                metadataId={metadataId}
                getMessagesMetaData={getMessagesMetaData}
                getOpenConversations={getOpenConversations}
                getInProgressConversations={getInProgressConversations}
                getAwaitingResponseConversations={
                  getAwaitingResponseConversations
                }
                getConversationsCountAction={getConversationsCountAction}
                showConvoHistory={showConvoHistory}
                openConvoHistory={openConvoHistory}
                closeConvoHistory={closeConvoHistory}
                newDlr={newDlr}
                allUsers={allUsers}
                allAgents={allAgents}
                channelsData={channelsData}
              />

              <div
                className={classNames("convo_sidebar_content", {
                  showSidebar: displaySidebar,
                })}
              >
                <div
                  className={classNames("toggle-display", {
                    active: displaySidebar,
                  })}
                  onClick={() => {
                    setDisplaySidebar(false);
                    setTimeout(() => {
                      setSidebarContent(null);
                    }, 500);
                  }}
                >
                  <AngleLeft />
                </div>

                {sidebarContent === "integration" && (
                  <Integrations
                    selectedConversation={selectedConversation}
                    toggleTicketForm={handleToggleTicketForm}
                    patchTicketFormData={patchTicketFormData}
                  />
                )}

                {sidebarContent === "customer" && (
                  <CustomerInfo
                    selectedConversation={selectedConversation}
                    openConvoHistory={openConvoHistory}
                  />
                )}
              </div>

              <ConvoSidebar
                sidebarAction={sidebarContent}
                setSidebarAction={(value) => {
                  setSidebarContent(value);
                  setDisplaySidebar(true);
                }}
              />
            </>
          ) : (
            <div className="empty-state">
              <div className="icon">
                {channelsData?.length === 0 ? (
                  <Queue />
                ) : (
                  emptyStateContent.icon
                )}
              </div>
              <span>
                {channelsData?.length === 0
                  ? "Add channels to start having conversations"
                  : emptyStateContent.text}
              </span>
              <br />
              {channelsData?.length === 0 && (
                <Link to="/get-started" className="get_started_block_button">
                  Go back to set up
                </Link>
              )}
            </div>
          )}

          {isNewConversationOpen && (
            <Modal closeModal={closeModal}>
              <div className="modal-content new-conversation-container">
                <div className="modal-title">
                  <h6>Start new conversation from</h6>
                  <div className="new-conversation-channel">
                    {selectedChannel?.value?.medium === "WHATSAPP" && (
                      <WhatsApp />
                    )}
                    <Select
                      menuPlacement="bottom"
                      classNamePrefix="select_container"
                      value={selectedChannel}
                      options={channelOptions}
                      onChange={handleChangeChannel}
                    />
                  </div>
                </div>
                <div className="modal-form new-conversation-form">
                  {!recipientSelected && (
                    <div className="new-recipient">
                      <label>To: </label>
                      <input
                        placeholder="Type a name or number"
                        className="input"
                        onKeyDown={handleOnKeyDown}
                        disabled={!selectedChannel?.value?.medium}
                        onChange={(e) => {
                          setNewRecipient({ phone: e.target.value });
                        }}
                      />
                    </div>
                  )}
                  {recipientSelected && (
                    <div className="start-new-conversation-container">
                      <div className="start-new-conversation">
                        <div className="d-flex">
                          <span className="text">
                            To:{" "}
                            <strong>
                              {newRecipient?.name || newRecipient?.phone}
                            </strong>
                          </span>
                        </div>
                        <div
                          className="edit"
                          onClick={() => {
                            setNewRecipient("");
                            selectRecipient(false);
                          }}
                        >
                          Edit
                        </div>
                      </div>
                      {!newRecipient?.phone && (
                        <p className="error">Recepient phone not found</p>
                      )}
                      {isValidating && (
                        <p className="error loading">Validating...</p>
                      )}
                    </div>
                  )}
                  {selectedChannel?.value?.medium &&
                    customersContact &&
                    !recipientSelected && (
                      <div className="chat-container-contacts">
                        {customersContact?.map((contact, i) => (
                          <div key={i} className="contact-container">
                            <h6>{contact?.letter}</h6>
                            {contact?.list.map((item, i) => (
                              <div
                                key={i}
                                className="contact"
                                onClick={() => {
                                  setNewRecipient(item);
                                  selectRecipient(true);
                                }}
                              >
                                <Avatar text={getInitials(item?.name)} />
                                <span>{item?.name || item?.phone}</span>
                              </div>
                            ))}
                          </div>
                        ))}
                      </div>
                    )}
                  <div className="modal-form-action new-conversation-action">
                    <Button className="secondary-button" onClick={closeModal}>
                      Cancel
                    </Button>
                    <Button
                      className="primary-button"
                      disabled={
                        !recipientSelected ||
                        isValidating ||
                        !selectedChannel?.value?.medium ||
                        checkingExistingCustomer
                      }
                      onClick={validateNumberOrId}
                    >
                      {isValidating
                        ? "Validating..."
                        : isOpeningConvo
                        ? "Opening Conversation..."
                        : "Start a Conversation"}
                    </Button>
                  </div>
                </div>
              </div>
            </Modal>
          )}

          <Toaster {...toaster} closeToaster={closeToaster} />

          {isTicketFormOpen && (
            <TicketForm
              integration={integrationInfo}
              conversation_id={id}
              toggleTicketForm={toggleTicketForm}
              ticketFormData={ticketFormData}
              getConversation={getConversation}
              selectedConversation={selectedConversation}
            />
          )}
        </div>
      </Suspense>
    </>
  );
};

const mapStateToProps = ({ customers }) => customers;

export default withRouter(
  connect(mapStateToProps, {
    getCustomersAction,
  })(ChatContainer)
);
